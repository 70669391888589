<template>
<aheader />
<section class="section">
  <section class="section">
    <div class="columns">
      <div class="column is-8 is-offset-2">
          <div class="title is-4">
            お問い合わせを承りました。
            <br/>ご回答までしばらくお待ち下さい。
            <br/>本件に関するお問合せの際には下記お問い合わせ番号をお伝えいただくとスムーズです。
            <br/>お問い合わせ番号：{{inquiryID}}
          </div>
      </div>
    </div>
  </section>

</section>
<afooter/>
</template>
<script>
export default {
  data() {
    return {
      inquiryID: ""
    }
  },
  created() {
    this.inquiryID = this.$store.state.inquiryID;
    this.$store.commit('setIquryID', "");
  },
  mounted() {
    this.scrollTop();
  },
  methods: {
    scrollTop() {
      this.$nextTick(() => {
        window.scrollTo(0, 0);
      })
    },
    pushPage(page) {
      this.$router.push(page);
    }
  }
}
</script>

<style scoped>

</style>
